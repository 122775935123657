import React, { useState } from 'react';
import Layout from '../components/layout'
import Accordion from '../components/accordianModal';

import lighting from "../images/graphic/industrail/lighting.png";
import power from "../images/graphic/industrail/powerManagement.png";
import refridgeration from "../images/graphic/industrail/refridgeration.png";
import solar from "../images/graphic/industrail/solar.png";
import spaceHeating from "../images/graphic/industrail/spaceHeating.png";

import mainIMG from "../images/graphic/industrail/main.png"

const GraphicIndustrial = (props) => {
    const modalList = [
        {
            title: "Power Management",
            desc: "Implementing power management across your facility’s systems enables automation and insights that you can use to custom tune your operations, maximize energy savings, reduce costs and improve production.",
            desc2: "",
            cost: "2",
            energy: "3",
            pay: "4",
            Akey: power,
        },
        {
            title: "Space Heating and Cooling",
            desc: "HVAC can represent a considerable portion of a facility’s energy use. There are many ways to improve HVAC efficiency, from adjusting fan operation during unoccupied hours and implementing energy management systems to installing economizers, utilizing heat recovery, minimizing exhaust and make-up air, and upgrading to the latest equipment. Implementing a modern climate control system gives you insights into your building’s energy performance while allowing you to implement system setpoints, establish custom climate zones and occupancy controls, and adjust operating hours — which can all reduce your HVAC energy use and save you money.",
            desc2: "",
            cost: "4",
            energy: "4",
            pay: "2",
            Akey: spaceHeating,
        },
        {
            title: "Solar",
            desc: "When paired with battery storage, on-site solar can help you advance your sustainability goals and reduce your energy costs while also providing greater reliability and resiliency.",
            desc2: "",
            cost: "1",
            energy: "1",
            pay: "5",
            Akey: solar,
        },
        {
            title: "Lighting",
            desc: "Switching to LEDs presents one of the easiest energy efficiency programs for facilities to implement. Lighting upgrades have a short payback period, typically realizing ROI between one and three years. Additionally, modern high-efficiency LED light sources provide more illumination at a lower operating cost — meaning you can improve your work environment while also improving energy performance. Beyond upgrading to LED lights, occupancy sensors offer additional energy-saving opportunities.",
            desc2: "",
            cost: "3",
            energy: "2",
            pay: "3",
            Akey: lighting,
        },
        {
            title: "Refrigeration Systems",
            desc: "Process cooling and industrial refrigeration account for nearly 6% of industrial energy consumption in the U.S. Various energy efficiency opportunities exist, with system-level approaches providing the most impactful energy savings. Additionally, we can help you establish comprehensive maintenance protocols for your equipment, including cleaning cooling and heating coils, burners and radiators.",
            desc2: "",
            cost: "5",
            energy: "5",
            pay: "1",
            Akey: refridgeration,
        }
    ];

    let param = props.location.search;
    let filter = param.slice(8);
    const [filterName, setfilterName] = useState('this string');

    
    let buttonSelctor = (buttonID) => {
        let btns = document.querySelectorAll('.filterBtn'); 
        btns.forEach(element => element.classList.remove('choosenFilter'));
        if(filterCost === true || filterEnergy === true || filterCarbon === true){
            document.querySelector("#"+buttonID).classList.add('choosenFilter');
        }
    }
    const [dataObject, setdataObject] = useState(modalList);
    const costSort = () => {
        buttonSelctor('cost');
        setfilterName("Cost Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.cost) - Number(b.cost));
          return dataToSort;
        });
    };
    const energySort = () => {
        buttonSelctor('energy');
        setfilterName("Energy Usage Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.energy) - Number(b.energy));
          return dataToSort;
        });
    };
    const paySort = () => {
        buttonSelctor('carbon');
        setfilterName("Carbon Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.pay) - Number(b.pay));
          return dataToSort;
        });
    };
    const [Image, setImage] = useState(mainIMG);
    const setImageVar = (e) => {
        if(Image === e){
            setImage(mainIMG);
        }
        else{
            setImage(e);
        }
    }
    const [filterCost, setfilterCost] = useState(false);
    if(filterCost !== true && filter === 'cost'){
        costSort();
        setfilterCost(true);
    }

    const [filterEnergy, setfilterEnergy] = useState(false);
    if(filterEnergy !== true && filter === 'energy'){
        energySort();
        setfilterEnergy(true);
    }

    const [filterCarbon, setfilterCarbon] = useState(false);
    if(filterCarbon !== true && filter === 'carbon'){
        paySort();
        setfilterCarbon(true);
    }

    return (
        <Layout nextBottomSlideBtn="Implementation" nextBottomSlideBtnLink="/Implementation" id="works">
            <ul className="priceNav">
                <strong>Sort by:</strong>
                <li onClick={costSort}><button className="filterBtn" id="cost" >Cost Reduction</button></li>
                <li onClick={paySort}><button className="filterBtn" id="carbon">Carbon Reduction</button></li>
                <li onClick={energySort}><button className="filterBtn" id="energy">Energy Usage Reduction</button></li>
            </ul>
            <div className="fullWidth">
                <div className="buttons">
                    <h1 id="filterName"><strong>{filterName}</strong></h1>
                    <br></br>
                    <div className="options">
                        {dataObject.map((data) => (
                            <Accordion 
                                title={data.title} 
                                desc={data.desc}
                                desc2={data.desc2}
                                annualCost={data.annualCost}
                                payback={data.paybac}
                                annualCarbon={data.annualCarbon}
                                upfront={data.upfront}
                                cost={data.cost} 
                                energy={data.energy} 
                                pay={data.pay}
                                Akey={data.Akey}
                                onClick = { () => setImageVar(data.Akey)}
                            />
                        ))}
                    </div>
                </div>
                <div className="graphic">
                    <img
                        alt="Industry"
                        src={Image}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default GraphicIndustrial